import React from "react";
import AboutMain from "./AboutMain";
import AboutTeam from "./AboutTeam";

const About = () => {
  return (
    <div>
      <AboutMain />

      <AboutTeam />
    </div>
  );
};

export default About;
