import React from "react";
import "../../styles/about/aboutteam.css";
import Team1 from "../../Assets/default.png";
import Team2 from "../../Assets/avater (2).png";
import Team3 from "../../Assets/avater (3).png";
import Team4 from "../../Assets/avater (4).png";
import TedyImg from "../../Assets/FH1A5911.png";
import Fade from "react-reveal/Fade";

const AboutTeam = () => {
  return (
    <div>
      {" "}
      <section className="About__teams">
        <Fade bottom duration={1500}>
          <div className="Team__Text">
            Our <span className="Team__span">Team </span>
          </div>
          <div className="Header">
            <h1>Meet our team member</h1>
            {/* <p>
              Praesent sagittis eros in convallis rutrum. Donec auctor nibh
              justo. Vestibu <br></br>tincidunt, libero sit amet vestibulum
              euismod, ex nisl imperdiet leo.
            </p> */}
          </div>
          {/* =============Avater================= */}
          <div className="Team__container">
            <div>
              <img src={TedyImg} alt="" style={{ borderRadius: '135px' , width: '270px' , height : '270px' }} />
              <h3>Tewodros Atnafu</h3>
              <p>CEO & Founder</p>
            </div>
            <div>
              {" "}
              <img src={Team1} alt="" style={{ borderRadius: '135px' , width: '270px' , height : '270px' }} />
              <h3>Selam T/haimanot</h3>
              <p>COO</p>
            </div>
            <div>
              <img src={Team1} alt="" style={{ borderRadius: '135px' , width: '270px' , height : '270px' }} />
              <h3>Samrawit Atnafu</h3>
              <p>Project Lead</p>
            </div>
            <div>
              <img src={Team1} alt="" style={{ borderRadius: '135px' , width: '270px' , height : '270px' }} />
              <h3>Tekalegne Defar</h3>
              <p>Technical Lead</p>
            </div>
          </div>
          <button className="About__Team__btn">
            <p>
              <a href="/meet-the-team">Meet the Team</a>
            </p>
          </button>
        </Fade>
      </section>
    </div>
  );
};

export default AboutTeam;
