import React from "react";

import Portifoliobanner from "../Portifoliosecond/Portifoliobanner";
import Portifolioabout from "./Portifolioabout";
import Portifoliopic from "./Portifoliopic";


const Portifoliosecond = () => {
  return (
    <div>
 <Portifoliobanner/>
 <Portifolioabout/>
 <Portifoliopic/>
    </div>
  );
};

export default Portifoliosecond;