import React from "react";
import "../../styles/about/aboutteam.css";
import Fade from "react-reveal/Fade";

const teamMembers = [
  {
    name: "Muluken Zeleke",
    title: "Front-End Developer",
    image: require("../../Assets/team/muluken.png"),
  },
  {
    name: "Bethemariam Asrat",
    title: "Back-End Developer",
    image: require("../../Assets/team/beti_prev_ui (1).png"),
  },
  {
    name: "Solomon Kassahun",
    title: "Front-End Developer",
    image: require("../../Assets/team/Solomon Kasahun.jpg"),
  },
  {
    name: "Awoke Guadie",
    title:  "Front-End Developer",
    image: require("../../Assets/team/aweke.jpg"),
  },
  {
    name: "Amanuel Alemayehu",
    title: "Quality Control",
    image: require("../../Assets/team/Amanuel Alemayehu.jpg"),
  },
  {
    name: "Fasika Habtu",
    title: "Quality Control",
    image: require("../../Assets/team/fasika_prev_ui (1).png"),
  },
  {
    name: "Amanuel Beyena",
    title: "Front-End Developer",
    image: require("../../Assets/team/Amanuel beyena.jpg"),
  },
  {
    name: "Ermiyas Sene",
    title: "QC Team Leader",
    image: require("../../Assets/team/ermiyas.jpg"),
  },
  {
    name: "Mehari Melaku",
    title: "Back-End Developer",
    image: require("../../Assets/team/mehari.png"),
  },
  {
    name: "Melkie Kindie",
    title:"Back-End Team Leader",
    image: require("../../Assets/team/melkie_prev_ui.png"),
  },
  {
    name: "Weldsh Aweke",
    title: "Front-End Team Leader",
    image: require("../../Assets/team/weldish.jpg"),
  },
  {
    name: "yosef berhanu ehetu",
    title: "Quality Control",
    image: require("../../Assets/team/yosef.jpg"),
  },
  {
    name: "Natnael Legesse",
    title: "Back-End Developer",
    image: require("../../Assets/team/nati_prev_ui (1).png"),
  },
  {
    name: "Azemeraw Aschale",
    title: "Back-End Developer",
    image: require("../../Assets/team/azi_prev_ui.png"),
  },
  
];

const AboutTeam = () => {
  return (
    <div>
      <section className="About__teams">
        <Fade bottom duration={1500}>
          <div className="Header">
            <h1>Meet our team members</h1>
          </div>
          <div className="team-gallery">
            {teamMembers.map((member, index) => (
              <Fade bottom duration={1000} key={index}>
                <div className="team-member">
                  <img src={member.image} alt={member.name} />
                  <h3>{member.name}</h3>
                  <p>{member.title}</p>
                </div>
              </Fade>
            ))}
          </div>
        </Fade>
      </section>
    </div>
  );
};

export default AboutTeam;